import { createReducer } from '@reduxjs/toolkit';
import {
  cleanLoan,
  requestLoan,
  requestLoanSuccess,
  requestLoanFail,
  editLoan,
} from './actions';
import { isEqual, sortBy } from 'lodash';

const initialState = {
  loan: {
    debt: '',
    interest: '',
    installment: '360',
    otherFees: '0',
    amortizationValue: '',
    amortizationInterest: '0.5',
    amortizationCadency: '12',
    accumulateAmortization: false,
  },
  selectedLoan: null,
  historical: [],
  historicalTotal: [],
  processing: false,
};

export const defaultAmortization = {
  amortizationValue: '0',
  amortizationInterest: '0.5',
  amortizationCadency: '12',
  accumulateAmortization: false,
};

export const loansReducer = createReducer(initialState, {
  [requestLoan]: (state, action) => {
    state.processing = true;
  },
  [requestLoanSuccess]: (state, action) => {
    const { input, result, noAmortizationResult } = action.payload;

    if (result) {
      const oldValue = state.historical?.find(v =>
        isEqual(v.input, action.payload.input)
      );

      const noAmortizationLoanInput = {
        ...action.payload.input,
        ...defaultAmortization,
      };
      const noAmortizationResultHistorical = state.historicalTotal?.find(v =>
        isEqual(v.input, noAmortizationLoanInput)
      );

      if (!noAmortizationResultHistorical) {
        state.historicalTotal = [
          {
            input: noAmortizationLoanInput,
            result: noAmortizationResult,
          },
          ...state.historicalTotal,
        ];
      }

      if (!oldValue) {
        state.historical = [
          {
            date: new Date().getTime(),
            input,
            result,
            noAmortizationResult,
            id: Date.now(),
          },
          ...state.historical,
        ];
      } else {
        oldValue.date = new Date().getTime();
        state.historical = [
          ...sortBy(state.historical, a => -new Date(a.date)),
        ];
      }
      state.processing = false;
    }
  },
  [requestLoanFail]: (state, action) => {
    state.processing = false;
  },
  [editLoan]: (state, action) => {
    const { key, value } = action.payload;
    state.loan = { ...state.loan, [key]: value };
    state.processing = false;
  },
  [cleanLoan]: (state, action) => {
    state.loan = { ...initialState.loan };
    state.historical = [];
    state.processing = false;
  },
});

export const getLoan = state => state.loans.loan;
export const getHistorical = state => state.loans.historical;
export const getCalculatedLoan = state => {
  const currentLoan = getLoan(state);
  const historical = getHistorical(state);

  return historical?.find(v => isEqual(v.input, currentLoan))?.result;
};
export const getCalculatedLoanByLoan = (state, loan) => {
  const historical = [...getHistorical(state), ...state.loans.historicalTotal];

  return historical?.find(v => isEqual(v.input, loan))?.result;
};
export const getSelectedLoan = state => state.loans.selectedLoan;
