/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Stack,
  Container,
  SimpleGrid,
  useBreakpointValue,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';

import { Nav } from './components/navigationBar';
import { HistoricalComponent } from './loans/historical.component';
import { LoanComponent } from './loans/loan.component';

export const Home = () => {
  return (
    <>
      <Nav />
      <Box position={'relative'}>
        <Container
          as={SimpleGrid}
          maxW={'8xl'}
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 10, lg: 10 }}
          py={{ base: 10, sm: 10, lg: 10 }}
        >
          <Stack
            bg={useColorModeValue('gray.50', 'blackAlpha.800')}
            rounded={'xl'}
            p={{ base: 4, sm: 6, md: 8 }}
            spacing={{ base: 8 }}
          >
            <LoanComponent />
          </Stack>
          <Stack
            bg={useColorModeValue('gray.50', 'blackAlpha.800')}
            rounded={'xl'}
            p={{ base: 4, sm: 6, md: 8 }}
            spacing={{ base: 8 }}
          >
            <HistoricalComponent></HistoricalComponent>
          </Stack>
        </Container>
        <Blur
          position={'absolute'}
          top={-10}
          left={-10}
          style={{ filter: 'blur(70px)' }}
        />
      </Box>
    </>
  );
};

export const Blur = props => {
  return (
    <Icon
      width={useBreakpointValue({ base: '100%', md: '40vw', lg: '30vw' })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: -1 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  );
};
