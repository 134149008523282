import { Box, Flex, useColorModeValue, Stack } from '@chakra-ui/react';
import { ColorModeSwitcher } from './colorSwitcher';

export const Nav = () => {
  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <Box>Calculadora de Amortização</Box>

          <Flex alignItems={'center'}>
            <Stack direction={'row'} spacing={7}>
              <ColorModeSwitcher />
            </Stack>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
