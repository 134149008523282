import React from 'react';
import {
  Box,
  Stack,
  Heading,
  Text,
  Input,
  Button,
  SimpleGrid,
  FormLabel,
  InputGroup,
  FormControl,
  InputRightElement,
  FormHelperText,
  Switch,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  IconButton,
} from '@chakra-ui/react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { cleanLoan, editLoan, requestLoan } from './actions';
import { QuestionIcon } from '@chakra-ui/icons';
import { Link } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const isAnyOptionUndefined = loan => {
  const props = [
    'debt',
    'interest',
    'installment',
    'otherFees',
    'amortizationValue',
    'amortizationInterest',
    'amortizationCadency',
    'accumulateAmortization',
  ];

  return !props.every(p => !isEmpty(loan[p]?.toString() || ''));
};

const Tip = ({ description }) => (
  <Popover placement="top">
    <PopoverTrigger>
      <IconButton size="sm" isRound icon={<QuestionIcon />} />
    </PopoverTrigger>
    <PopoverContent>
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverBody>{description}</PopoverBody>
    </PopoverContent>
  </Popover>
);

export const LoanComponentView = props => {
  const { loan, processing } = props;

  const calculateLoanFn = () => {
    const { dispatch } = props;

    const { loan } = props;

    if (isAnyOptionUndefined(loan)) {
      console.log('...');
    } else {
      dispatch(requestLoan());
    }
  };

  const cleanLoanFn = () => {
    const { dispatch } = props;
    dispatch(cleanLoan());
  };

  const editLoanFn = (key, value) => {
    const { dispatch } = props;
    dispatch(editLoan({ key, value }));
  };

  return (
    <>
      <Stack spacing={4}>
        <Heading
          color={useColorModeValue('gray.800', 'gray.200')}
          lineHeight={1.1}
          fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
        >
          Parâmetros
        </Heading>
        <Text
          color={useColorModeValue('gray.500', 'gray.50')}
          fontSize={{ base: 'sm', sm: 'md' }}
        >
          Insira os dados e verifique quanto pode poupar. Pode consultar mais
          informação seguindo esta documentação do{' '}
          <Link
            href="https://www.boonzi.pt/como-acabar-rapidamente-com-divida-simulador-amortizacoes-credito-habitacao/"
            isExternal
          >
            Boonzi <ExternalLinkIcon mx="2px" />
          </Link>
          ou do{' '}
          <Link
            href="https://www.doutorfinancas.pt/prestacao-apos-amortizacao-antecipada/"
            isExternal
          >
            Doutor Finanças <ExternalLinkIcon mx="2px" />
          </Link>
          .
        </Text>
      </Stack>
      <Box as={'form'} mt={10}>
        <Stack spacing={4}>
          <SimpleGrid columns={2} gap={4}>
            <Box>
              <FormControl id="debt" isRequired>
                <FormLabel>
                  Valor em dívida{' '}
                  <Tip description={'Valor total em dívida neste momento'} />
                </FormLabel>
                <InputGroup>
                  <Input
                    type="number"
                    placeholder="100000"
                    value={loan.debt}
                    onChange={e => editLoanFn('debt', e.target.value)}
                  />
                  <InputRightElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                    children="€"
                  />
                </InputGroup>
              </FormControl>
            </Box>
            <Box>
              <FormControl id="interest" isRequired>
                <FormLabel>
                  Taxa de juro{' '}
                  <Tip
                    description={
                      'Taxa de juro aplicada - TAN (Taxa anual nominal). Nota que para taxa variável, este valor será aplicado para os anos remanescentes.'
                    }
                  />
                </FormLabel>
                <InputGroup>
                  <Input
                    type="number"
                    placeholder="1,6"
                    value={loan.interest}
                    onChange={e => editLoanFn('interest', e.target.value)}
                  />
                  <InputRightElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                    children="%"
                  />
                </InputGroup>
              </FormControl>
            </Box>
            <Box>
              <FormControl id="installments" isRequired>
                <FormLabel>
                  Prestações em falta{' '}
                  <Tip
                    description={
                      'Número de prestações em falta até ao término do crédito.'
                    }
                  />
                </FormLabel>
                <InputGroup>
                  <Input
                    type="number"
                    placeholder="300"
                    value={loan.installment}
                    onChange={e => editLoanFn('installment', e.target.value)}
                  />
                  <InputRightElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                    children="#"
                  />
                </InputGroup>
              </FormControl>
            </Box>
            <Box>
              <FormControl id="other-costs" isRequired>
                <FormLabel>
                  Outros custos mensais{' '}
                  <Tip
                    description={
                      'Podem ser incluídos os valores de seguros, imposto de selo, ou outras despesas associadas ao abate mensal do crédito.'
                    }
                  />
                </FormLabel>
                <InputGroup>
                  <Input
                    type="number"
                    placeholder="20"
                    value={loan.otherFees}
                    onChange={e => editLoanFn('otherFees', e.target.value)}
                  />
                  <InputRightElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                    children="€"
                  />
                </InputGroup>
              </FormControl>
            </Box>
            <Box>
              <FormControl id="amortization-value" isRequired>
                <FormLabel>
                  Valor a despender{' '}
                  <Tip
                    description={
                      'Quanto pretende despender para o abate do crédito para a cadência selecionada.'
                    }
                  />
                </FormLabel>
                <InputGroup>
                  <Input
                    type="number"
                    placeholder="1200"
                    value={loan.amortizationValue}
                    onChange={e =>
                      editLoanFn('amortizationValue', e.target.value)
                    }
                  />
                  <InputRightElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                    children="€"
                  />
                </InputGroup>
              </FormControl>
            </Box>
            <Box>
              <FormControl id="amortization-interest" isRequired>
                <FormLabel>
                  Taxa de amortização{' '}
                  <Tip
                    description={
                      'Taxa aplicada para cada amortização. Normalmente 0.5% para taxa variável e 2% para taxa fixa.'
                    }
                  />
                </FormLabel>
                <InputGroup>
                  <Input
                    type="number"
                    placeholder="0.5"
                    value={loan.amortizationInterest}
                    onChange={e =>
                      editLoanFn('amortizationInterest', e.target.value)
                    }
                  />
                  <InputRightElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                    children="%"
                  />
                </InputGroup>
              </FormControl>
            </Box>
            <Box>
              <FormControl id="amortization-cadency" isRequired>
                <FormLabel>
                  Cadência de amortização{' '}
                  <Tip
                    description={
                      'De quanto em quanto tempo pretende amortizar. De 12 em 12 meses parece ser um bom equilíbrio.'
                    }
                  />
                </FormLabel>
                <InputGroup>
                  <Input
                    type="number"
                    placeholder="12"
                    value={loan.amortizationCadency}
                    onChange={e =>
                      editLoanFn('amortizationCadency', e.target.value)
                    }
                  />
                  <InputRightElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                    children="#"
                  />
                </InputGroup>
                <FormHelperText>a cada 12 meses</FormHelperText>
              </FormControl>
            </Box>
            <Box>
              <FormControl id="amortization-comb" isRequired>
                <FormLabel>
                  Combinado{' '}
                  <Tip
                    description={
                      'Após cada amortização o valor da mensalidade do crédito é reduzida. Pretende acumular esse valor e juntar ao valor a despender?'
                    }
                  />
                </FormLabel>
                <Switch
                  size="lg"
                  margin="5px 0 5px 0"
                  isChecked={loan.accumulateAmortization}
                  onChange={e =>
                    editLoanFn('accumulateAmortization', e.target.checked)
                  }
                />
                <FormHelperText>reaplica o valor poupado</FormHelperText>
              </FormControl>
            </Box>
          </SimpleGrid>
        </Stack>
        <SimpleGrid columns={2} gap={4}>
          <Box>
            <Button
              fontFamily={'heading'}
              mt={8}
              colorScheme="gray"
              variant="outline"
              onClick={cleanLoanFn}
              w={'full'}
            >
              Apagar
            </Button>
          </Box>
          <Box>
            <Button
              isLoading={processing}
              fontFamily={'heading'}
              loadingText="A calcular..."
              mt={8}
              w={'full'}
              bgGradient="linear(to-r, red.400,pink.400)"
              color={'white'}
              _hover={{
                bgGradient: 'linear(to-r, red.400,pink.400)',
                boxShadow: 'xl',
              }}
              onClick={calculateLoanFn}
            >
              Calcular
            </Button>
          </Box>
        </SimpleGrid>
      </Box>
    </>
  );
};

const mapStateToProps = (state, props) => {
  return { loan: state.loans.loan, processing: state.loans.processing };
};

export const LoanComponent = connect(mapStateToProps)(LoanComponentView);
