import React from 'react';
import { StoreProvider } from './configuration/store';
import { ThemeProvider } from './configuration/theme';
import { Home } from './home.page';

function App() {
  return (
    <StoreProvider>
      <ThemeProvider>
        <Home />
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
