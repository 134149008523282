/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { connect } from 'react-redux';
import Big from 'big.js';
import {
  Box,
  Stack,
  Heading,
  Text,
  SimpleGrid,
  useColorModeValue,
  AccordionItem,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Grid,
  GridItem,
} from '@chakra-ui/react';

export const HistoricalComponentView = props => {
  const { historical } = props;

  if (historical == null || historical.length === 0) {
    return (
      <>
        <Stack spacing={4}>
          <Heading
            color={useColorModeValue('gray.800', 'gray.200')}
            lineHeight={1.1}
            fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
          >
            Histórico
          </Heading>
          <p>Sem dados de histórico.</p>
        </Stack>
      </>
    );
  }

  const data = (historical || [])
    .map(h => {
      try {
        const noAmortization = h.noAmortizationResult;

        const noAmortizationtotalInterest = Big(
          noAmortization.totalPaidInterest
        );
        const amortization = h.result;

        const totalInterest = Big(amortization.totalPaidInterest);

        const save = noAmortizationtotalInterest.minus(totalInterest);
        const percentageSave = save.div(noAmortizationtotalInterest).mul(100);

        const amortizationValue = Big(h.input.amortizationValue)
          .div(h.input.amortizationCadency)
          .mul(12);
        const accumulated = h.input.accumulateAmortization
          ? 'acumulado'
          : 'não acumulado';

        const y = Big(amortization.totalAmountForAmortization);

        const win = y.toNumber() === 0 ? Big(0) : save.div(y);

        return {
          title: `Poupa ${save.toFixed(0)}€ em taxas (${percentageSave.toFixed(
            2
          )}%) com incremento de ${amortizationValue.toFixed(
            0
          )} anual (${accumulated}).`,
          description: `Rentabilização do dinheiro investido: ${save} / ${y.toFixed(
            0
          )} = ${win.mul(100).toFixed(2)}%`,
          loan: h.input,
          amortization,
          noAmortization,
          id: h.id,
          result: h.result,
        };
      } catch (e) {
        console.error(e);
        return null;
      }
    })
    .filter(v => v != null);

  return (
    <Stack spacing={4}>
      <Heading
        color={useColorModeValue('gray.800', 'gray.200')}
        lineHeight={1.1}
        fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
      >
        Histórico
      </Heading>

      <Accordion defaultIndex={[]} allowMultiple>
        {data.map(v => (
          <AccordionItem key={v.id}>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {v.title}
                  <p>{v.description}</p>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Box>
                <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('pink.500', 'pink.400')}
                  fontWeight={'500'}
                  textTransform={'uppercase'}
                  mb={'1'}
                >
                  Parâmetros
                </Text>
                <SimpleGrid columns={2} gap={1} marginBottom={4}>
                  <Box>
                    <Text as={'span'} fontWeight={'bold'}>
                      Valor em dívida:
                    </Text>{' '}
                    {v.loan.debt}€
                  </Box>
                  <Box>
                    <Text as={'span'} fontWeight={'bold'}>
                      Taxa de juro
                    </Text>{' '}
                    {v.loan.interest}%
                  </Box>
                  <Box>
                    <Text as={'span'} fontWeight={'bold'}>
                      Prestações em falta:
                    </Text>{' '}
                    {v.loan.installment}
                  </Box>
                  <Box>
                    <Text as={'span'} fontWeight={'bold'}>
                      Outros custos
                    </Text>{' '}
                    {v.loan.otherFees}€
                  </Box>
                  <Box>
                    <Text as={'span'} fontWeight={'bold'}>
                      Valor a despender:
                    </Text>{' '}
                    {v.loan.amortizationValue}€
                  </Box>
                  <Box>
                    <Text as={'span'} fontWeight={'bold'}>
                      Taxa de amortização
                    </Text>{' '}
                    {v.loan.amortizationInterest}%
                  </Box>
                  <Box>
                    <Text as={'span'} fontWeight={'bold'}>
                      Cadência:
                    </Text>{' '}
                    a cada {v.loan.amortizationCadency} meses
                  </Box>
                  <Box>
                    <Text as={'span'} fontWeight={'bold'}>
                      Combinado:
                    </Text>{' '}
                    {v.loan.accumulateAmortization ? 'sim' : 'não'}
                  </Box>
                </SimpleGrid>
                <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('pink.500', 'pink.400')}
                  fontWeight={'500'}
                  textTransform={'uppercase'}
                  mb={'1'}
                >
                  Resumo
                </Text>
                <SimpleGrid columns={2} gap={1} marginBottom={4}>
                  <Box align={'center'}>
                    <Text as={'span'} fontWeight={'bold'}>
                      Sem amortização
                    </Text>
                  </Box>
                  <Box align={'center'}>
                    <Text as={'span'} fontWeight={'bold'}>
                      Com amortização
                    </Text>
                  </Box>
                  <Box>
                    <Text as={'span'} fontWeight={'bold'}>
                      Juros:
                    </Text>{' '}
                    {v.noAmortization.totalPaidInterest}€
                  </Box>
                  <Box>
                    <Text as={'span'} fontWeight={'bold'}>
                      Juros:
                    </Text>{' '}
                    {v.amortization.totalPaidInterest}€
                  </Box>
                  <Box>
                    <Text as={'span'} fontWeight={'bold'}>
                      Número de prestações:
                    </Text>{' '}
                    {v.noAmortization.totalInstallments}
                  </Box>
                  <Box>
                    <Text as={'span'} fontWeight={'bold'}>
                      Número de prestações:
                    </Text>{' '}
                    {v.amortization.totalInstallments}
                  </Box>
                </SimpleGrid>
                <Text
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('pink.500', 'pink.400')}
                  fontWeight={'500'}
                  textTransform={'uppercase'}
                  mb={'1'}
                >
                  Detalhes
                </Text>
                <Grid templateColumns="repeat(5, 1fr)" gap={0}>
                  <GridItem
                    textAlign={'center'}
                    borderBottom={'1px solid #aaa'}
                  >
                    Prestação
                  </GridItem>
                  <GridItem
                    textAlign={'center'}
                    borderBottom={'1px solid #aaa'}
                  >
                    Pagamento
                  </GridItem>
                  <GridItem
                    textAlign={'center'}
                    borderBottom={'1px solid #aaa'}
                  >
                    Crédito
                  </GridItem>
                  <GridItem
                    textAlign={'center'}
                    borderBottom={'1px solid #aaa'}
                  >
                    Mensalidade
                  </GridItem>
                  <GridItem
                    textAlign={'center'}
                    borderBottom={'1px solid #aaa'}
                  >
                    Amortização
                  </GridItem>

                  {v.result.details.map(r => (
                    <React.Fragment key={v.id + r.installments}>
                      <GridItem
                        textAlign={'center'}
                        borderBottom={'1px solid #aaa'}
                      >
                        {r.installments}
                      </GridItem>
                      <GridItem
                        textAlign={'center'}
                        borderBottom={'1px solid #aaa'}
                      >
                        {r.totalPaid}€
                      </GridItem>
                      <GridItem
                        textAlign={'center'}
                        borderBottom={'1px solid #aaa'}
                      >
                        {r.debt}€
                      </GridItem>
                      <GridItem
                        textAlign={'center'}
                        borderBottom={'1px solid #aaa'}
                      >
                        {r.installmentPayment.total}€
                      </GridItem>
                      <GridItem
                        textAlign={'center'}
                        borderBottom={'1px solid #aaa'}
                      >
                        {r.amortizationPayment.total}€
                      </GridItem>
                    </React.Fragment>
                  ))}
                </Grid>
              </Box>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Stack>
  );
};

const mapStateToProps = (state, props) => {
  return {
    historical: state.loans.historical,
  };
};

export const HistoricalComponent = connect(mapStateToProps)(
  HistoricalComponentView
);
