import { createAction } from '@reduxjs/toolkit';

export const EDIT_LOAN = 'EDIT_LOAN';
export const CLEAN_LOAN = 'CLEAN_LOAN';
export const REQUEST_LOAN = 'REQUEST_LOAN';
export const REQUEST_LOAN_SUCCESS = 'REQUEST_LOAN_SUCCESS';
export const REQUEST_LOAN_FAIL = 'REQUEST_LOAN_FAIL';
export const SELECT_LOAN = 'SELECT_LOAN';
export const UNSELECT_LOAN = 'UNSELECT_LOAN';

export const editLoan = createAction(EDIT_LOAN);
export const cleanLoan = createAction(CLEAN_LOAN);
export const requestLoan = createAction(REQUEST_LOAN);
export const requestLoanSuccess = createAction(REQUEST_LOAN_SUCCESS);
export const requestLoanFail = createAction(REQUEST_LOAN_FAIL);

export const selectLoan = createAction(SELECT_LOAN);
export const unselectLoan = createAction(UNSELECT_LOAN);
