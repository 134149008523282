import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import AsyncStorage from '@react-native-community/async-storage';
import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { reducers } from './reducers';
import { loansSagas } from './sagas';

const persistConfig = {
  key: 'loans-app-v2rc2',

  storage: AsyncStorage,
};

const sagaMiddleware = createSagaMiddleware();

const persistReducers = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistReducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([sagaMiddleware]),
});
sagaMiddleware.run(loansSagas);

let persistedStore = persistStore(store);

export const StoreProvider = props => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistedStore}>
        {props.children}
      </PersistGate>
    </Provider>
  );
};
