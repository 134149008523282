import { ChakraProvider, extendTheme, ColorModeScript } from '@chakra-ui/react';
const config = {
  initialColorMode: 'system',
};
const theme = extendTheme({ config });

export const ThemeProvider = props => (
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />

    <ChakraProvider theme={theme}>{props.children}</ChakraProvider>
  </>
);
