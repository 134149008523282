import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { REQUEST_LOAN } from './actions';
import {
  defaultAmortization,
  getCalculatedLoan,
  getCalculatedLoanByLoan,
  getLoan,
} from './reducer';
import Big from 'big.js';
import { calculateLoanAmortization } from './api';
import camelcaseKeys from 'camelcase-keys';

function* requestLoan(action) {
  try {
    const loan = yield select(getLoan);
    const noAmortizationLoan = { ...loan, ...defaultAmortization };
    const noAmortizationResult =
      (yield select(getCalculatedLoanByLoan, noAmortizationLoan)) ||
      (yield call(() => calculateLoan(noAmortizationLoan)));
    const result =
      (yield select(getCalculatedLoan)) ||
      (yield call(() => calculateLoan(loan)));

    yield put({
      type: 'REQUEST_LOAN_SUCCESS',
      payload: { input: loan, result, noAmortizationResult },
    });
  } catch (e) {
    yield put({
      type: 'REQUEST_LOAN_FAIL',
    });
  }
}

async function calculateLoan(loan) {
  const interest = Big(loan.interest).div(Big(100));
  const debt = Big(loan.debt);
  const installments = Big(loan.installment);
  const fee = Big(loan.amortizationInterest).div(Big(100));
  const otherComissions = Big(loan.otherFees);

  const result = await calculateLoanAmortization({
    loan: {
      debt: debt.toNumber(),
      interest: interest.toNumber(),
      installments: installments.toNumber(),
      other_fees: otherComissions.toNumber(),
    },
    amortization: {
      value: Big(loan.amortizationValue).toNumber(),
      interest: fee.toNumber(),
      cadence: Big(loan.amortizationCadency).toNumber(),
      accumulated: loan.accumulateAmortization,
    },
  });

  return camelcaseKeys(result, { deep: true });
}
function* loansSagas() {
  yield all([takeLatest(REQUEST_LOAN, requestLoan)]);
}

export { loansSagas };
